import React, { Suspense } from "react";
import styled from "styled-components";
import { Typography } from "@shared/ui/Typography";
import dynamic from "next/dynamic";
import { IHeaderContactData } from "@features/Layout/types";
import Link from "next/link";

const Icon = dynamic(() => import("@shared/ui/Icon"), {
  suspense: true,
});

interface IProps {
  headerContactData: IHeaderContactData[];
  className?: string;
}

export function HeaderContactInfo({ headerContactData, className }: IProps) {
  return (
    <StyledUpperContainer className={className}>
      {headerContactData.map(({ icon, type, value, id }) =>
        type === "link" ? (
          <Link href={`tel:${value}`}>
            <StyledUpperItem key={id}>
              <Suspense fallback="icon">
                <StyledIcon name={icon} iconColor="basic_black" iconSize={20} />
              </Suspense>
              <StyledUpperItemValue fontSize="1.4rem">
                {value}
              </StyledUpperItemValue>
            </StyledUpperItem>
          </Link>
        ) : (
          <StyledUpperItem key={id}>
            <Suspense fallback="icon">
              <StyledIcon name={icon} iconColor="basic_black" iconSize={20} />
            </Suspense>
            <StyledUpperItemValue fontSize="1.4rem">
              {value}
            </StyledUpperItemValue>
          </StyledUpperItem>
        )
      )}
    </StyledUpperContainer>
  );
}

const StyledUpperContainer = styled.div`
  padding: 0.4rem 4rem;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 3rem;
  align-items: center;
  justify-content: flex-end;
  margin: 0 auto;
  background-color: #f5f5f5;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    grid-auto-flow: row;
    grid-gap: 0.8rem;
    background-color: initial;
    padding: 0;
    margin: initial;
    justify-content: flex-start;
  }
`;

const StyledUpperItem = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 1rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    justify-content: flex-start;

    & svg {
      path {
        fill: ${(props) => props.theme.colors.basic_white};
        opacity: 1;
      }
    }
  }
`;

const StyledIcon = styled(Icon)`
  opacity: 0.7;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    opacity: 1;
    & svg {
      path {
        fill: ${(props) => props.theme.colors.basic_white} !important;
      }
    }
  }
`;

const StyledUpperItemValue = styled(Typography)`
  color: rgba(63, 63, 63, 0.7);

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    font-size: 1.4rem;
    color: ${(props) => props.theme.colors.basic_white};
  }
`;
