import styled from "styled-components";
import { motion } from "framer-motion";
import { Typography } from "@shared/ui/Typography";

const Nav = styled(motion.nav)`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2.8rem;
  height: inherit;
`;

const SubMenu = styled(motion.div)`
  position: absolute;
  height: 7.3rem;
  bottom: -7.3rem;
  left: 0;
  right: 0;
  z-index: 1000;
  justify-content: center;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2.8rem;
  background-color: ${(props) => props.theme.colors.basic_white};
  box-shadow: inset ${(props) => props.theme.shadow["10dp"]},
    ${(props) => props.theme.shadow["10dp"]};
`;

const Underline = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 1rem 1rem 0 0;
  height: 2px;
  background: ${({ theme }) => theme.colors.primary_red};
`;

const SubLink = styled(Typography)`
  position: relative;
  cursor: pointer;
  display: grid;
  align-items: center;
  padding: 2.4rem 1rem;
  &:hover {
    color: ${({ theme }) => theme.colors.primary_red};
  }
`;

export default {
  Nav,
  SubMenu,
  Underline,
  SubLink,
};
