import Link from "next/link";
import styled, { css } from "styled-components";
import { Typography } from "@shared/ui/Typography";
import { INavItem } from "@shared/routes/types";

interface IProps {
  link: INavItem;
}

export function FooterLinkColumn({ link }: IProps) {
  return (
    <StyledColumn>
      <Link href={link.url}>
        <StyledTypography weight={700} fontSize="1.6rem" color="basic_white">
          {link.title.toUpperCase()}
        </StyledTypography>
      </Link>
      {link.children.map((subLink) => (
        <Link key={subLink.id} href={subLink.url}>
          <StyledTypography weight={400} fontSize="1.6rem" color="basic_white">
            {subLink.title}
          </StyledTypography>
        </Link>
      ))}
    </StyledColumn>
  );
}

const StyledColumn = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 2rem;
  align-content: flex-start;
`;

const StyledTypography = styled(Typography)`
  ${({ theme }) => css`
    cursor: pointer;

    &:hover {
      color: ${theme.colors.primary_dark_gray};
    }

    @media screen and (max-width: ${theme.sizes.tablet}) {
      &:hover {
        color: ${theme.colors.basic_white};
      }
    }
  `}
`;
