import React from "react";
import styled from "styled-components";
import { Typography } from "@shared/ui/Typography";
import { IbecSystemsLogo } from "public/static/icons";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { INavItem } from "@shared/routes/types";
import { FooterLinks } from "./Nav";
import { AgreementLinks } from "./AgreementLinks";

interface IProps {
  nav: INavItem[];
}

export function FooterBottom({ nav }: IProps) {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <FooterLinks nav={nav} />
      <StyledBottom>
        <StyledCopyright fontSize="1.6rem" weight={400} color="basic_white">
          {t("© 2022 BNK. Все права защищены.")}
        </StyledCopyright>
        <AgreementLinks />
        <StyledLink href="https://ibecsystems.com/" target="_blank">
          <IbecSystemsLogo />
        </StyledLink>
      </StyledBottom>
    </StyledFooter>
  );
}

const StyledFooter = styled.div`
  padding: 3.2rem 0 4rem;
  display: grid;
  grid-auto-flow: row;
  grid-gap: 5rem;
`;

const StyledBottom = styled.div`
  display: grid;
  align-items: center;
  grid-auto-flow: column;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    grid-template-columns: auto;
    grid-template-areas:
      "links links"
      "copyright ibec";
    grid-row-gap: 3.2rem;
  }
`;

const StyledCopyright = styled(Typography)`
  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    grid-area: copyright;
    font-size: 1.2rem;
  }
`;

const StyledLink = styled(Link)`
  justify-self: flex-end;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    grid-area: ibec;
  }
`;
