import React, { useEffect, useState } from "react";
import Image from "next/image";
import styled from "styled-components";
import Logo from "public/static/logo.svg";
import { motion } from "framer-motion";
import { BurgerMenu } from "@shared/ui/BurgerMenu";
import { INavItem } from "@shared/routes/types";
import { IHeaderContactData } from "@features/Layout/types";
import Link from "next/link";
import { routePaths } from "@shared/routes";
import { useTranslation } from "next-i18next";
import { HeaderNav } from "./Nav";
import { LanguageSelector } from "./LanguageSelector";
import { MobileMenu } from "./MobileMenu";
import { HeaderContactInfo } from "./HeaderContactInfo";

interface IProps {
  nav: INavItem[];
}

export function Header({ nav }: IProps) {
  const { t } = useTranslation();
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);

  const upperHeaderData: IHeaderContactData[] = [
    { id: 0, icon: "Location", value: t("Ауэзова 60(уг. ул. Жамбыла)") },
    { id: 1, icon: "Time-Circle-7", value: t("pn-pt-s-9-00-do-18-00") },
    { id: 2, icon: "Call", value: "5210", type: "link" },
  ];
  useEffect(() => {
    if (isOpenMobileMenu) {
      document.body.classList.add("active-modal");
    } else {
      document.body.classList.remove("active-modal");
    }
  }, [isOpenMobileMenu]);

  return (
    <StyledHeader open={isOpenMobileMenu}>
      <StyledHeaderContactInfo headerContactData={upperHeaderData} />
      <StyledContainer>
        <Link href={routePaths.main.index}>
          <StyledImage src={Logo} alt="logo" />
        </Link>
        <StyledWrapper>
          <HeaderNav navItems={nav} />
        </StyledWrapper>
        <LanguageSelector />
        <StyledBurgerMenu
          toggleMenu={() => setIsOpenMobileMenu(!isOpenMobileMenu)}
          isOpen={isOpenMobileMenu}
        />
      </StyledContainer>
      <MobileMenu
        navItems={nav}
        headerContactData={upperHeaderData}
        isOpen={isOpenMobileMenu}
        closeMenu={() => setIsOpenMobileMenu(false)}
      />
    </StyledHeader>
  );
}

const StyledHeaderContactInfo = styled(HeaderContactInfo)`
  padding-block: 1rem;
  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    display: none;
  }
`;

const StyledContainer = styled.div`
  padding: 0 2.1rem;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  grid-gap: 1.6rem;
  align-items: center;
  max-width: ${(props) => props.theme.sizes["1440"]};
  margin: 0 auto;
  height: 6.719rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    padding: 0.8rem 1.6rem;
    margin: unset;
    height: auto;
    grid-template-columns: auto;
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledHeader = styled(motion.header)<{ open: boolean }>`
  box-shadow: ${(props) => props.theme.shadow["10dp"]};
  position: relative;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    height: ${(props) => (props.open ? "100%" : "5.6rem")};
    transition: all 0.3s ease-in-out;
    position: fixed;
    background-color: ${(props) =>
      props.open
        ? props.theme.colors.primary_light_gray
        : props.theme.colors.basic_white};
    z-index: 2;
    left: 0;
    right: 0;
    display: grid;
    grid-template-rows: auto 1fr;
  }
`;

const StyledImage = styled(Image)`
  max-height: 3.4rem;
  cursor: pointer;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    max-height: 4rem;
    width: auto;
    z-index: 3;
  }
`;

const StyledWrapper = styled.div`
  display: grid;
  justify-self: flex-end;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: center;
  grid-gap: 2.8rem;
  height: inherit;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    display: none;
  }
`;

const StyledBurgerMenu = styled(BurgerMenu)`
  display: none;
  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    display: block;
    justify-items: flex-end;
  }
`;
