import styled from "styled-components";
import { Typography } from "@shared/ui/Typography";
import { useRouter } from "next/router";
import { useMemo } from "react";
import Link from "next/link";
import { AnimatePresence, motion } from "framer-motion";
import { useTranslation } from "next-i18next";

export function LanguageSelectorMobile() {
  const router = useRouter();
  const { t } = useTranslation();

  const locales = {
    ru: t(`Рус`),
    kk: t(`Каз`),
    en: t(`Англ`),
  };

  const options = useMemo(() => Object.entries(locales), [router.locale]);

  return (
    <StyledView>
      <AnimatePresence>
        {options.map(([key, value]) => (
          <Link href={router.asPath} key={key} locale={key}>
            <StyledOption
              weight={500}
              color={router.locale === key ? "primary_red" : "basic_white"}
            >
              {value}
              {router.locale === key ? (
                <StyledUnderline layoutId="mobileLangSelector" />
              ) : null}
            </StyledOption>
          </Link>
        ))}
      </AnimatePresence>
    </StyledView>
  );
}

const StyledView = styled.div`
  position: relative;
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-start;
  grid-gap: 4rem;
  margin-bottom: 4.8rem;
`;

const StyledOption = styled(Typography)`
  position: relative;
  padding-bottom: 0.8rem;
  cursor: pointer;
`;

const StyledUnderline = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 1rem 1rem 0 0;
  height: 2px;
  background: ${({ theme }) => theme.colors.primary_red};
`;
