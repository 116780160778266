import React, { Fragment, Suspense, useState } from "react";
import styled from "styled-components";
import { Divider } from "@shared/ui/Divider";
import { Typography } from "@shared/ui/Typography";
import { INavItem } from "@shared/routes/types";
import { AnimatePresence, motion } from "framer-motion";
import dynamic from "next/dynamic";
import { routePaths } from "@shared/routes";
import Link from "next/link";

const Icon = dynamic(() => import("@shared/ui/Icon"), {
  suspense: true,
});

export function MobileNav(props: {
  navItems: INavItem[];
  closeMenu: () => void;
}) {
  const [expanded, setExpanded] = useState<false | number>(0);

  return (
    <StyledNav>
      {props.navItems.map((item) => (
        <Fragment key={item.id}>
          {!item.children.length ? (
            <Link href={item.url}>
              <StyledTypography
                onClick={props.closeMenu}
                weight={500}
                fontSize="1.6rem"
              >
                {item.title}
              </StyledTypography>
            </Link>
          ) : (
            <>
              <StyledTypography
                onClick={() =>
                  setExpanded(item.id === expanded ? false : item.id)
                }
                weight={500}
                fontSize="1.6rem"
              >
                {item.title}
                <Suspense fallback="icon">
                  <Icon name="Down-2" iconColor="basic_white" iconSize={20} />
                </Suspense>
              </StyledTypography>
              <AnimatePresence initial={false}>
                {item.id === expanded && (
                  <StyledContent
                    key="content"
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={{
                      open: { height: "auto", opacity: 1 },
                      collapsed: { height: 0, opacity: 0 },
                    }}
                    transition={{
                      duration: 0.6,
                      ease: [0.04, 0.62, 0.23, 0.98],
                    }}
                  >
                    {item.children.map((subItem) => (
                      <Link
                        href={
                          item.slug === "produkty"
                            ? `${routePaths.main.products.index}/${subItem.slug}`
                            : subItem.url
                        }
                      >
                        <StyledWrapper
                          key={subItem.id}
                          onClick={props.closeMenu}
                        >
                          <Typography color="basic_white">
                            {subItem.title}
                          </Typography>
                        </StyledWrapper>
                      </Link>
                    ))}
                  </StyledContent>
                )}
              </AnimatePresence>
            </>
          )}
          <Divider />
        </Fragment>
      ))}
    </StyledNav>
  );
}

const StyledNav = styled.ul`
  display: grid;
  grid-auto-flow: row;
  align-content: flex-start;
  margin-bottom: 4.8rem;
`;

const StyledTypography = styled(Typography)`
  cursor: pointer;

  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: space-between;
  padding: 2rem 0;

  color: ${(props) => props.theme.colors.basic_white};
`;

const StyledContent = styled(motion.div)`
  overflow: hidden;
  display: grid;
`;

const StyledWrapper = styled(motion.div)`
  padding: 0 1.6rem 2rem 1.6rem;
`;
