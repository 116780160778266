import styled from "styled-components";
import { Typography } from "@shared/ui/Typography";
import { LangExpandIcon } from "public/static/icons";
import { useRouter } from "next/router";
import { useMemo } from "react";
import Link from "next/link";
import { AnimatePresence, motion } from "framer-motion";
import { useComponentVisible } from "@shared/lib/hooks/useComponentVisible";

export function LanguageSelector() {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const routes = useRouter();

  const locales = {
    ru: "Русский",
    kk: "Қазақша",
    en: "English",
  };

  const toggleOptions = () => {
    setIsComponentVisible((value) => !value);
  };

  const options = useMemo(
    () => Object.entries(locales).filter(([key]) => key !== routes.locale),
    [routes.locale]
  );

  return (
    <StyledButton onClick={toggleOptions}>
      <StyledLabel weight={500} fontSize="1.6rem">
        {locales[routes.locale]}
      </StyledLabel>
      <LangExpandIcon />
      <AnimatePresence>
        {isComponentVisible && (
          <StyledOptions
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            ref={ref}
          >
            {options.map(([key, value]) => (
              <StyledLink href={routes.asPath} key={key} locale={key}>
                <StyledOption tag="div">{value}</StyledOption>
              </StyledLink>
            ))}
          </StyledOptions>
        )}
      </AnimatePresence>
    </StyledButton>
  );
}

const StyledLabel = styled(Typography)`
  color: rgba(63, 63, 63, 0.7);
`;

const StyledOptions = styled(motion.div)`
  position: absolute;
  top: 3rem;
  right: 0;
  display: grid;
  grid-auto-flow: initial;
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.colors.basic_white};
  box-shadow: ${(props) => props.theme.shadow["20dp"]};
  z-index: 1000;
  width: 19.2rem;
  padding-block: 0.8rem;
`;

const StyledButton = styled.button`
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-gap: 0.4rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    display: none;
  }
`;

const StyledLink = styled(Link)`
  display: block;
`;

const StyledOption = styled(Typography)`
  text-align: left;
  padding: 0.8rem 1.6rem;
`;
