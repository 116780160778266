import React from "react";
import styled, { css } from "styled-components";
import { motion } from "framer-motion";

interface IProps {
  toggleMenu: () => void;
  isOpen: boolean;
  className?: string;
}

export function BurgerMenu({ toggleMenu, isOpen, className }: IProps) {
  return (
    <StyledBurger
      className={className}
      onClick={toggleMenu}
      type="button"
      open={isOpen}
    >
      <span className="burger__item" />
    </StyledBurger>
  );
}

export const StyledBurger = styled(motion.button)<{ open: boolean }>`
  -webkit-transition: all 0.3s ease-out;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  background: transparent;
  padding: 0;
  border: none;
  position: relative;
  outline: none !important;

  & > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: ${(props) => (props.open ? 0 : "2px")};
    background: ${(props) =>
      props.open
        ? props.theme.colors.basic_white
        : props.theme.colors.basic_black};
    border-radius: 0.2rem;

    &:after,
    &:before {
      content: "";
      position: absolute;
      right: 0;
      width: 2rem;
      height: 0.2rem;
      border-radius: 0.2rem;
      background: ${(props) => props.theme.colors.basic_black};
      -webkit-transition: all 0.2s ease-out;
    }

    &:before {
      top: -0.8rem;
      ${(props) =>
        props.open &&
        css`
          transform: translate(-50%, -50%) rotate(45deg);
          top: 50%;
          left: 50%;
          background: ${props.theme.colors.basic_white};
        `}
    }

    &:after {
      top: 0.8rem;
      width: 2rem;
      ${(props) =>
        props.open &&
        css`
          transform: translate(-50%, -50%) rotate(-45deg);
          top: 50%;
          left: 50%;
          width: 2rem;
          background: ${props.theme.colors.basic_white};
        `}
    }
  }
`;
