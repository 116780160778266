import React from "react";
import styled from "styled-components";
import { InstagramIcon, WhatsappIcon } from "public/static/icons";
import { SocialIcon } from "@shared/ui/SocialIcon";

interface IProps {
  className?: string;
}

export function SocialIcons({ className }: IProps) {
  const links = [
    {
      id: 1,
      Icon: InstagramIcon,
      href: "https://www.instagram.com/bnk_finance/?igshid=YmMyMTA2M2Y%3D",
    },
    { id: 2, Icon: WhatsappIcon, href: "https://wa.me/77013458090" },
  ];

  return (
    <StyledIcons className={className}>
      {links.map((link) => (
        <SocialIcon key={link.id} Icon={link.Icon} href={link.href} />
      ))}
    </StyledIcons>
  );
}

const StyledIcons = styled.div`
  display: grid;
  grid-gap: 1.6rem;
  grid-auto-flow: column;
  justify-content: flex-end;
`;
