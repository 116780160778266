import React from "react";
import styled from "styled-components";
import { INavItem } from "@shared/routes/types";
import { FooterLinkColumn } from "./FooterLink";

interface IProps {
  nav: INavItem[];
}

export function FooterLinks({ nav }: IProps) {
  return (
    <StyledNav>
      {nav.map((item) => (
        <FooterLinkColumn key={item.id} link={item} />
      ))}
    </StyledNav>
  );
}

const StyledNav = styled.nav`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 3.8rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
    display: none;
  }
`;
