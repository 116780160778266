import styled, { css } from "styled-components";
import { motion } from "framer-motion";

export const Button = styled(motion.button)<{
  $model?: "outlined" | "filled" | "rounded";
}>`
  ${({ $model = "filled", theme }) => {
    const bgc = {
      filled: theme.colors.primary_red,
      rounded: theme.colors.primary_red,
      outlined: "none",
    };
    // todo: нет цвета в палитре
    const hover = {
      filled: "#9e1916",
      rounded: "#9e1916",
      outlined: "none",
    };

    const fontColor = {
      filled: theme.colors.basic_white,
      rounded: theme.colors.basic_white,
      outlined: theme.colors.primary_red,
    };

    const borderColor = {
      filled: theme.colors.basic_white,
      rounded: theme.colors.basic_white,
      outlined: theme.colors.primary_red,
    };

    return css`
      font-size: 1.8rem;
      font-weight: 500;
      padding: 1.6rem 4rem;
      border-radius: ${$model === "rounded" ? "0.8rem" : "0 1.2rem"};
      ${$model === "outlined" &&
      css`
        border: 1px solid ${borderColor[$model]};
      `}
      background-color: ${bgc[$model]};
      color: ${fontColor[$model]};
      cursor: pointer;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: ${hover[$model]};
      }

      &:disabled {
        opacity: 0.7;

        &:hover {
          background-color: ${theme.colors.primary_red};
        }
      }

      @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
        font-size: 1.6rem;
      }
    `;
  }}
`;
