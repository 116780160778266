import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { Container } from "@shared/ui/sharedstyles";
import { Divider } from "@shared/ui/Divider";
import { Button } from "@shared/ui/Button";
import Link from "next/link";
import { routePaths } from "@shared/routes";
import { useTranslation } from "next-i18next";
import { INavItem } from "@shared/routes/types";
import { useUnit } from "effector-react";
import { currentProductId } from "@shared/model";
import { FooterUpper } from "./FooterUpper";
import { FooterBottom } from "./FooterBottom";

interface IProps {
  additionalButtonsVisible: boolean;
  nav: INavItem[];
}

export function Footer({ additionalButtonsVisible, nav }: IProps) {
  const { t } = useTranslation();
  const productId = useUnit(currentProductId);

  const switchConsultationButton = () => {
    const start = 9 * 60;
    const end = 18 * 60;
    const date = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Almaty",
    });
    const now = new Date(date).getHours() * 60 + new Date(date).getMinutes();

    if (
      start <= now &&
      now <= end &&
      ![0, 7].includes(new Date(date).getDay())
    ) {
      return (
        <a href="https://wa.me/77013458090" target="_blank" rel="noreferrer">
          <StyledButton $model="outlined">{t(`WhatsApp`)}</StyledButton>
        </a>
      );
    }

    return (
      <Link href={routePaths.main.products.consultation}>
        <StyledButton $model="outlined">
          {t(`Бронирование консультации`)}
        </StyledButton>
      </Link>
    );
  };

  useEffect(() => {
    switchConsultationButton();
  }, []);

  return (
    <StyledFooter>
      <StyledFooterUpper>
        <Container>
          <FooterUpper />
          <StyledDivider />
          <FooterBottom nav={nav} />
        </Container>
      </StyledFooterUpper>
      {additionalButtonsVisible && <StyledFiller />}
      {additionalButtonsVisible && (
        <StyledFooterBottom>
          {switchConsultationButton()}
          <Link
            href={{
              pathname: routePaths.main.products.request,
              query: { productId },
            }}
          >
            <StyledButton>{t(`Оформить заявку`)}</StyledButton>
          </Link>
        </StyledFooterBottom>
      )}
    </StyledFooter>
  );
}

const StyledFooter = styled.footer``;

const StyledFooterUpper = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary_light_gray};
  `}
`;

const StyledFooterBottom = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  grid-gap: 2.4rem;
  padding: 1.6rem 1rem;
  background-color: ${(props) => props.theme.colors.basic_white};
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.07);

  @media screen and (max-width: ${(props) => props.theme.sizes.tabletMin}) {
    grid-auto-flow: unset;
    padding: 1.6rem;
    grid-gap: 1.6rem;
    justify-content: stretch;
  }
`;

const StyledFiller = styled.div`
  height: 8.6rem;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    height: 15rem;
  }
`;

const StyledDivider = styled(Divider)`
  // todo: нет цвета в палитре
  border-bottom: 1px solid #978d7b;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;
