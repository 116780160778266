import React from "react";
import styled, { css } from "styled-components";
import { ThemeColorsType } from "@providers/ThemeProvider/theme";

interface IProps {
  Icon: React.FunctionComponent;
  href: string;
  iconColor?: ThemeColorsType;
  iconSize?: "3.2rem" | "4rem";
  backgroundColor?: ThemeColorsType;
}

export function SocialIcon(props: IProps) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      <StyledView
        $iconColor={props.iconColor}
        $backgroundColor={props.backgroundColor}
        $iconSize={props.iconSize}
      >
        <props.Icon />
      </StyledView>
    </a>
  );
}

const StyledView = styled.div<{
  $iconColor?: IProps["iconColor"];
  $backgroundColor?: IProps["backgroundColor"];
  $iconSize?: IProps["iconSize"];
}>`
  ${({
    theme,
    $iconColor = "basic_white",
    $backgroundColor = "basic_white",
    $iconSize = "3.2rem",
  }) => css`
    width: ${$iconSize};
    height: ${$iconSize};
    border-radius: 50%;
    padding: calc(${$iconSize} / 4.5);
    background-color: ${theme.colors[$backgroundColor]}1A;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: ${theme.colors[$backgroundColor]}66;
    }

    & svg {
      width: 100%;
      height: 100%;
      path {
        fill: ${theme.colors[$iconColor]};
      }
    }
  `}
`;
