import React, { useMemo, useState } from "react";
import { INavItem } from "@shared/routes/types";
import { AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";
import { routePaths } from "@shared/routes";
import Styled from "./styled";
import { HeaderLink } from "../HeaderLink";

export function HeaderNav(props: { navItems: INavItem[] }) {
  const router = useRouter();
  const [selected, setSelected] = useState(-1);
  const [subMenuVisible, setSubMenuVisible] = useState(false);

  const onHoverStart = (id: number) => {
    setSelected(id);
    setSubMenuVisible(
      Boolean(props.navItems.find((item) => item.id === id)?.children?.length)
    );
  };

  const onLinkClick = (href: string) => {
    router.push(href);
    setSubMenuVisible(false);
  };

  const selectedSubMenu = useMemo(
    () => props.navItems.find((item) => item.id === selected),
    [selected]
  );

  return (
    <AnimatePresence mode="wait">
      {props.navItems.length && (
        <Styled.Nav
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 0.3 } }}
        >
          {props.navItems.map((item) => (
            <HeaderLink
              key={item.id}
              href={item.url}
              title={item.title}
              selected={item.id === selected}
              onHoverStart={() => onHoverStart(item.id)}
            />
          ))}
          <AnimatePresence>
            {Boolean(selectedSubMenu?.children?.length) && subMenuVisible && (
              <Styled.SubMenu
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                onHoverEnd={() => setSubMenuVisible(false)}
              >
                {selectedSubMenu?.children.map((link) => (
                  <Styled.SubLink
                    key={link.id}
                    weight={500}
                    fontSize="1.6rem"
                    onClick={() =>
                      onLinkClick(
                        selectedSubMenu.slug === "produkty"
                          ? `${routePaths.main.products.index}/${link.slug}`
                          : link.url
                      )
                    }
                  >
                    {link.title}
                  </Styled.SubLink>
                ))}
              </Styled.SubMenu>
            )}
          </AnimatePresence>
        </Styled.Nav>
      )}
    </AnimatePresence>
  );
}
