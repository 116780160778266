export const routePaths = {
  index: "/",
  main: {
    index: "/main/",
    products: {
      request: "/main/product/application/",
      consultation: "/main/product/consultation/",
      index: "/main/products",
      collateralFreeLoans: "/main/products/collateral-free-loans/",
      carLoans: "/main/products/car-loans/",
      securedLoans: "/main/products/secured-loans/",
    },
    contract: "/main/contract/",
    guide: {
      index: "/main/bnk-guide/",
      news: "/main/bnk-guide/news/",
      career: {
        index: "/main/bnk-guide/career/",
        vacancies: "/main/bnk-guide/career/vacancies/",
        vacanciesFilter: "/main/bnk-guide/career/vacancies/filter",
      },
      about: {
        company: "/main/bnk-guide/about-company/o-kompanii/",
        head: "/main/bnk-guide/about-company/generalnyy-direktor/",
      },
    },
    article: {
      personalData: "/main/article/personalnye-dannye/",
    },
  },
  webview: {
    guide: {
      index: "/webview/bnk-guide/",
      news: "/webview/bnk-guide/news/",
      career: {
        index: "/webview/bnk-guide/career/",
        vacancies: "/webview/bnk-guide/career/vacancies/",
        vacanciesFilter: "/webview/bnk-guide/career/vacancies/filter",
      },
      about: {
        company: "/webview/bnk-guide/about-company/o-kompanii/",
        head: "/webview/bnk-guide/about-company/generalnyy-direktor/",
      },
    },
  },
};
