import Link from "next/link";
import styled from "styled-components";
import { Typography } from "@shared/ui/Typography";
import { motion } from "framer-motion";

interface IProps {
  title: string;
  href: string;
  selected: boolean;
  onHoverStart: () => void;
  className?: string;
}

export function HeaderLink({
  title,
  href,
  selected,
  onHoverStart,
  className,
}: IProps) {
  return (
    <Link href={href}>
      <StyledTypography
        className={className}
        weight={500}
        fontSize="1.6rem"
        onHoverStart={onHoverStart}
      >
        {title}
        {selected ? <StyledUnderline layoutId="underline" /> : null}
      </StyledTypography>
    </Link>
  );
}

const StyledTypography = styled(Typography)`
  position: relative;
  cursor: pointer;
  display: grid;
  align-items: center;
  padding: 2.4rem 1rem;
  &:hover {
    color: ${({ theme }) => theme.colors.primary_red};
  }
`;

const StyledUnderline = styled(motion.div)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 1rem 1rem 0 0;
  height: 2px;
  background: ${({ theme }) => theme.colors.primary_red};
`;
