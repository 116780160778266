import { createEffect, createStore, fork } from "effector";
import { axiosInstance } from "@shared/api";
import { INavItem } from "@shared/routes/types";

export type IMainMenu = { header: INavItem[]; footer: INavItem[] };
export type MainMenuResponseType = [{ [key: string]: INavItem[] }];

export interface IDocument {
  name: string;
  url: string;
}
export interface IMainDocuments {
  terms: IDocument;
  policy: IDocument;
  amfok: IDocument;
}

export const getMainMenuFx = createEffect(
  async ({ locale }: { locale: string }) => {
    const response = await axiosInstance.get<MainMenuResponseType>(`/menus`, {
      headers: {
        "Accept-Language": locale,
      },
    });
    return response.data;
  }
);

export const getDocumentsFx = createEffect(
  async ({ locale }: { locale: string }) => {
    const response = await axiosInstance.get<IMainDocuments>(`/files`, {
      headers: {
        "Accept-Language": locale,
      },
    });
    return response.data;
  }
);

export const $mainMenu = createStore<IMainMenu>({
  header: [],
  footer: [],
}).on(getMainMenuFx.doneData, (state, payload) => {
  const header = payload.find((item) => Object.keys(item).includes("header"));
  const footer = payload.find((item) => Object.keys(item).includes("footer"));
  return { footer: footer.footer, header: header.header };
});

export const $mainDocuments = createStore<IMainDocuments | null>(null).on(
  getDocumentsFx.doneData,
  (state, payload) => payload
);

export const layoutScope = fork();
