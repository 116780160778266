import React, { useMemo } from "react";
import styled from "styled-components";
import { Typography } from "@shared/ui/Typography";
import { useUnit } from "effector-react";
import { $mainDocuments } from "@features/Layout/model";

export function AgreementLinks() {
  const docs = useUnit($mainDocuments);
  const links = useMemo(() => {
    if (!docs) {
      return [];
    }

    return Object.values(docs);
  }, [docs]);

  return (
    <StyledNav>
      {links?.map((item) => (
        <a href={item.url} key={item.url} target="_blank" rel="noreferrer">
          <StyledTypography
            weight={400}
            fontSize="1.6rem"
            tag="span"
            color="basic_white"
          >
            {item.name}
          </StyledTypography>
        </a>
      ))}{" "}
    </StyledNav>
  );
}
const StyledNav = styled.nav`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 3.8rem;
  justify-content: flex-end;
  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    grid-area: links;
    grid-auto-flow: row;
    grid-gap: 1.2rem;
    justify-self: flex-start;
  }
`;

const StyledTypography = styled(Typography)`
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.primary_dark_gray};
  }

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    &:hover {
      color: ${({ theme }) => theme.colors.basic_white};
    }
  }
`;
