import React from "react";
import styled, { css } from "styled-components";
import { MobileNav } from "@features/Layout/ui/Header/MobileNav";
import { INavItem } from "@shared/routes/types";
import { motion } from "framer-motion";
import { HeaderContactInfo } from "@features/Layout/ui/Header/HeaderContactInfo";
import { LanguageSelectorMobile } from "@features/Layout/ui/Header/LanguageSelectorMobile";
import { IHeaderContactData } from "@features/Layout/types";

interface IProps {
  navItems: INavItem[];
  isOpen: boolean;
  closeMenu: () => void;
  headerContactData: IHeaderContactData[];
  className?: string;
}

const variants = {
  initial: { opacity: 0 },
  animate: {
    opacity: 1,
    transition: {
      delay: 0.3,
    },
  },
};

export function MobileMenu(props: IProps) {
  return (
    <StyledMenu
      animate={props.isOpen ? variants.animate : variants.initial}
      viewport={{ once: true }}
      $isOpen={props.isOpen}
      className={props.className}
    >
      <LanguageSelectorMobile />
      <MobileNav navItems={props.navItems} closeMenu={props.closeMenu} />
      <HeaderContactInfo headerContactData={props.headerContactData} />
    </StyledMenu>
  );
}

const StyledMenu = styled(motion.div)<{ $isOpen: IProps["isOpen"] }>`
  ${(props) => css`
    display: none;
    @media screen and (max-width: ${props.theme.sizes.tablet}) {
      pointer-events: ${props.$isOpen ? "auto" : "none"};
      display: grid;
      grid-auto-flow: row;
      padding: 4rem 1.6rem 9.6rem;
      justify-content: stretch;
      align-content: flex-start;
      overflow-y: auto;
    }
  `}
`;
