import React from "react";
import styled from "styled-components";
import Image from "next/image";
import Logo from "public/static/logo_footer.svg";
import { SocialIcons } from "@shared/ui/SocialIcons";

export function FooterUpper() {
  return (
    <StyledView>
      <Image src={Logo} alt="logo" />
      <SocialIcons />
    </StyledView>
  );
}

const StyledView = styled.div`
  padding: 2.8rem 0 2.5rem;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  justify-content: space-between;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    grid-auto-flow: row;
    justify-items: flex-start;
    grid-gap: 4.2rem;
    padding: 4.2rem 0 2.4rem;
  }
`;
