import React, { useEffect } from "react";
import styled from "styled-components";
import { useUnit } from "effector-react";
import { useRouter } from "next/router";
import { $mainMenu, getMainMenuFx, getDocumentsFx } from "./model";
import { Header } from "./ui/Header";
import { Footer } from "./ui/Footer";

interface IProps {
  children: React.ReactNode;
  additionalButtonsVisible?: boolean;
}

export const Layout = React.memo(
  ({ children, additionalButtonsVisible = false }: IProps) => {
    const { locale } = useRouter();
    const mainMenu = useUnit($mainMenu);
    const getMainMenu = useUnit(getMainMenuFx);
    const getDocuments = useUnit(getDocumentsFx);
    const { footer, header } = mainMenu;

    useEffect(() => {
      getMainMenu({ locale });
      getDocuments({ locale });
    }, [locale]);

    return (
      <StyledContainer>
        <Header nav={header} />
        <StyledPageContent>{children}</StyledPageContent>
        <Footer
          nav={footer}
          additionalButtonsVisible={additionalButtonsVisible}
        />
      </StyledContainer>
    );
  }
);

const StyledContainer = styled.main`
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  scroll-behavior: smooth;
  position: relative;

  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    grid-template-rows: 1fr auto;
  }
`;

const StyledPageContent = styled.div`
  @media screen and (max-width: ${(props) => props.theme.sizes.tablet}) {
    margin-top: 5.6rem;
  }
`;
