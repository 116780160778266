import { createEffect, createEvent, createStore } from "effector";
import { axiosInstance } from "@shared/api";
import { IConfirmPhoneData, IVerifyPhoneData } from "@shared/types";

export const setCurrentProductId = createEvent<number>();

export const currentProductId = createStore<null | number>(null).on(
  setCurrentProductId,
  (_, payload) => payload
);

export const verifyPhoneByCodeFx = createEffect(
  async (data: IVerifyPhoneData) => {
    const response = await axiosInstance.post<{}>(
      "/applications/verify-phone",
      data
    );

    return response.data;
  }
);

export const sendVerificationCode = createEffect(
  async (data: IVerifyPhoneData) => {
    await verifyPhoneByCodeFx(data);
  }
);

export const resendVerificationCode = createEffect(
  async (data: IVerifyPhoneData) => {
    await verifyPhoneByCodeFx(data);
  }
);

export const confirmPhoneFx = createEffect(async (data: IConfirmPhoneData) => {
  const response = await axiosInstance.post<{}>(
    "/applications/confirm-phone",
    data
  );

  return response.data;
});
